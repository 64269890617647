import React, {useContext, useState, useEffect, useCallback} from 'react';
import {Context} from '../AppContext';

export default function useDispatchList(props) {
  const {project, filters} = props;
  const app = useContext(Context);
  const [total, setTotal] = useState(0);
  const [records, setRecords] = useState(null);
  const [loading, setLoading] = useState(true);

  const getRecords = useCallback(
    async (search = '') => {
      setLoading(true);
      try {
        const params = {
          query: {
            project: project.id,
            display_id: {$regex: search},
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['-created'],
        };

        let resp = await app.actions.getDispatchs(params);
        setRecords(resp.results);
        setTotal(resp.total);
      } catch (ex) {
        console.warn(ex);
      }
      setLoading(false);
    },
    [app.actions, filters, project.id],
  );

  useEffect(() => {
    getRecords();

    return () => setRecords([]);
  }, [getRecords]);

  return {
    loading,
    records,
    total,
    getRecords,
  };
}
