import React from 'react';
import styled from 'styled-components';

export default function Field(props) {
  const {label, children, render, style} = props;
  return (
    <Wrapper style={style}>
      <Label>{label}</Label>
      {typeof render === 'function' ? render() : <Value>{children}</Value>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Label = styled.div`
  flex: 0 0 100px;
  color: #707070;
`;

const Value = styled.div`
  flex: 1;
`;
