import React, {useContext} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import useProjectItem from '../hooks/use-project-item';
import styled from 'styled-components';
import {Button} from '../Widgets';
import ProjectItem from '../Models/ProjectItem';
import formatValidator from '../Utils/formatValidator';
import {ErrProjectItem, errorHandler} from '../errors';
import {message, Row, Col} from 'antd';
import {fillZero} from '../Utils';

const {isNotEmpty} = formatValidator;

const LABELS = ProjectItem.labels;

export default function ProjectItemForm({record: _record, onUpdate}) {
  const {renderInput, renderCustom, record} = useForm({record: _record});
  const {submit} = useProjectItem({record, onUpdate});
  const edit = record.id || record.id === 0;

  return (
    <Wrapper>
      <h3>項目資訊</h3>
      <div className="content">
        {renderCustom(LABELS['id'], 'id', {
          render: ({value}) => <p>{edit ? `# ${fillZero(value + 1)}` : '#'}</p>,
        })}
        {renderInput(LABELS['name'], 'name')}
        {renderInput(LABELS['complete_size'], 'complete_size')}
        {renderInput(LABELS['expand_size'], 'expand_size')}
        {/* {renderInput(LABELS['pound'], 'pound')}
        {renderInput(LABELS['color'], 'color')} */}
        <Row gutter={10}>
          <Col span={12}>
            {renderInput(LABELS['quantity'], 'quantity', {
              extraProps: {type: 'number'},
            })}
          </Col>
          <Col span={12}>{renderInput(LABELS['unit'], 'unit')}</Col>
        </Row>
      </div>
      <div className="footer">
        <Button
          onClick={() => {
            submit(edit ? 'edit' : 'add');
          }}>
          確認
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    padding: 20px 30px;
  }

  & > .content {
    flex: 1;
    padding: 30px;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding: 20px 30px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
