class Project {
  constructor(record) {
    const {id = '', name = '', owner = null, owner_name = '', items = []} =
      record || {};
    this.id = id;
    this.name = name;
    this.owner = owner;
    this.owner_name = owner_name;
    this.items = items;
  }
}

Project.labels = {
  id: '專案編號',
  name: '專案名稱',
  owner: '客戶編號',
  owner_name: '客戶名稱',
  items: '印件',
  created: '建立時間',
};

module.exports = Project;
