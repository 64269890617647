import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Card, Row, Col, Tag} from 'antd';
import {Context} from '../../AppContext';
import ProjectItem from '../../Models/ProjectItem';
import ProjectItemForm from '../../Forms/ProjectItemForm';
import Field from '../../Components/Field';
import {Edit, Delete} from '@styled-icons/material';
import {fillZero} from '../../Utils';
import useProjectItem from '../../hooks/use-project-item';

const LABELS = ProjectItem.labels;

export default function ProjectItemList(props) {
  const {items: records, onUpdate} = props; // customer = instance
  const app = useContext(Context);

  return (
    <Wrapper>
      {records && (
        <Row gutter={[20, 20]}>
          {records.map((record, idx) => (
            <Col key={idx} xl={6} lg={8} md={12} sm={24}>
              <Item
                record={record}
                onEdit={() =>
                  app.actions.setModal(
                    <ProjectItemForm record={record} onUpdate={onUpdate} />,
                  )
                }
                onUpdate={onUpdate}
              />
            </Col>
          ))}
        </Row>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

function Item({record, onEdit, onUpdate}) {
  const deleted = record.is_deleted;

  const {submit} = useProjectItem({record, onUpdate});
  return (
    <Card
      title={`# ${fillZero(record.id + 1)} ${record.name}`}
      style={{
        borderRadius: 4,
        opacity: deleted ? 0.5 : 1,
      }}
      actions={
        !deleted
          ? [
              <div onClick={onEdit}>
                <Edit size="16" color="505050" />
              </div>,
              <div
                onClick={async () => {
                  if (window.confirm(`確認要刪除 ${record.name}？`)) {
                    submit('delete');
                  }
                }}>
                <Delete size="16" color="505050" />
              </div>,
            ]
          : null
      }
      extra={deleted && <Tag color="error">已刪除</Tag>}>
      <Field label={LABELS['complete_size']}>{record.complete_size}</Field>
      <Field label={LABELS['expand_size']}>{record.expand_size}</Field>
      {/* <Field label={LABELS['pound']}>{record.pound}</Field>
      <Field label={LABELS['color']}>{record.color}</Field> */}
      <Field label={LABELS['quantity']}>
        {record.quantity} {'   '}
        {record.unit}
      </Field>
    </Card>
  );
}
