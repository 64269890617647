import React, {useContext} from 'react';
import {Context} from '../AppContext';
import formatValidator from '../Utils/formatValidator';
import {ErrProjectItem, errorHandler} from '../errors';
import {message} from 'antd';

const {isNotEmpty} = formatValidator;

export default function useProjectItem({record, onUpdate}) {
  const app = useContext(Context);
  const project_id = record.project;

  const valid = () => {
    if (!isNotEmpty(record.name)) {
      throw new ErrProjectItem('項目名稱不能為空');
    }
  };

  const getProject = async () => {
    try {
      let resp = await app.actions.getProject({query: {id: project_id}});
      return resp;
    } catch (ex) {
      console.warn(ex);
    }
  };

  const submit = async (method) => {
    if (!method) {
      throw new Error('No specific method of submit project item form !');
    }

    app.actions.setModalLoading(true);
    app.actions.setLoading(true);

    const action = {
      edit: '編輯',
      add: '新增',
      delete: '刪除',
    }[method];

    const project = await getProject();
    let items = [...project.items] || [];

    try {
      valid(record);

      switch (method) {
        case 'add':
          items = [
            ...items,
            {
              ...record,
              id: items.length,
            },
          ];
          break;
        case 'edit':
          items[record.id] = record;
          break;
        case 'delete':
          record.is_deleted = true;
          items[record.id] = record;
          break;
        default:
          break;
      }

      const params = {
        query: {id: project_id},
        data: {
          ...project,
          items,
        },
      };

      await app.actions.editProject(params);

      onUpdate();
      app.actions.setModal();

      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setModalLoading(false);
    app.actions.setLoading(false);
  };

  return {
    submit,
  };
}
