import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import {Context} from '../../AppContext';
import Project from '../../Models/Project';
import {navigate} from 'gatsby';
import Block from '../../Components/Block';
import Field from '../../Components/Field';
import {Edit, Add, CopyAll} from '@styled-icons/material';
import ProjectForm from '../../Forms/ProjectForm';
import ProjectItem from '../../Models/ProjectItem';
import ProjectItemList from './ProjectItemList';
import ProjectItemForm from '../../Forms/ProjectItemForm';
import DispatchList from './DispatchList';
import {previousPage} from '../../Utils';
import CopyProjectForm from '../../Forms/CopyProjectForm';
const queryString = require('query-string');

const labels = Project.labels;

const dbg = {
  id: 'b3951612-984b-48b7-9a5f-65b4e30ac973',
};

export default function ProjectDetailPage(props) {
  const {id = dbg.id} = queryString.parse(props.location.search); //order.id
  const app = useContext(Context);
  const [record, setRecord] = useState();
  const [items, setItems] = useState([]);

  const getRecord = useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let resp = await app.actions.getProject({query: {id}});
      setRecord(resp);

      if (Array.isArray(resp.items)) {
        setItems(resp.items);
      }
    } catch (ex) {
      console.warn(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions, id]);

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  return (
    <Wrapper>
      <div className="action-bar">
        <div
          onClick={() => previousPage(`/customer?id=${record.owner}`)}
          style={{color: '#aaa', cursor: 'pointer'}}>
          {'↼ '}上一頁
        </div>

        <div style={{flex: 1}} />
      </div>
      <Row gutter={[30, 30]}>
        {record && (
          <Col span={12}>
            <Block
              title="專案資訊"
              actions={[
                {
                  icon: <CopyAll size="16" color="505050" />,
                  onClick: () => {
                    app.actions.setModal(
                      <CopyProjectForm record={record} onUpdate={getRecord} />,
                    );
                  },
                  title: '複製專案',
                },
                {
                  icon: <Edit size="16" color="505050" />,
                  onClick: () => {
                    app.actions.setModal(
                      <ProjectForm record={record} onUpdate={getRecord} />,
                    );
                  },
                },
              ]}>
              {/* <Field label={labels['id']}>{record.id}</Field> */}
              <Field label={labels['name']}>{record.name}</Field>
              {/* <Field label={labels['owner']}>{record.owner}</Field> */}
              <Field label={labels['owner_name']}>{record.owner_name}</Field>
            </Block>
          </Col>
        )}
        <Col span={12}></Col>

        {record && items && (
          <Col span={24}>
            <Block
              title="印製項目"
              actions={[
                {
                  icon: <Add size="16" color="505050" />,
                  onClick: () => {
                    app.actions.setModal(
                      <ProjectItemForm
                        record={
                          new ProjectItem({
                            project: record.id,
                          })
                        }
                        onUpdate={getRecord}
                      />,
                    );
                  },
                },
              ]}>
              <ProjectItemList items={items} onUpdate={getRecord} />
            </Block>
          </Col>
        )}

        {record && (
          <Col span={24}>
            <Block title="工單列表" contentStyle={{padding: 0}}>
              <DispatchList project={record} />
            </Block>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
