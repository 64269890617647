import React, {useContext} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import {Button} from '../Widgets';
import Project from '../Models/Project';
import formatValidator from '../Utils/formatValidator';
import {ErrProject, errorHandler} from '../errors';
import {message} from 'antd';
import {navigate} from 'gatsby';

const {isNotEmpty} = formatValidator;

const LABELS = Project.labels;

export default function ProjectForm({record: _record, onUpdate}) {
  const {renderInput, renderText, record} = useForm({record: _record});
  const app = useContext(Context);
  const edit = !!record.id;

  const valid = () => {
    if (!isNotEmpty(record.name)) {
      throw new ErrProject('專案名稱不能為空');
    }
  };

  const submit = async () => {
    const action = edit ? '編輯' : '新增';
    app.actions.setModalLoading(true);
    try {
      valid(record);

      const params = {
        query: edit ? {id: record.id} : {},
        data: record,
      };
      if (edit) {
        await app.actions.editProject(params);
      } else {
        await app.actions.addProject(params);
      }

      onUpdate();
      app.actions.setModal();

      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setModalLoading(false);
  };

  return (
    <Wrapper>
      <h3>專案資訊</h3>
      <div className="content">
        {/* {renderText(LABELS['id'], 'id')} */}
        {renderInput(LABELS['name'], 'name')}
        {/* {renderText(LABELS['owner'], 'owner')} */}
        {renderText(LABELS['owner_name'], 'owner_name')}
      </div>
      <div className="footer">
        <Button onClick={submit}>確認</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    padding: 20px 30px;
  }

  & > .content {
    flex: 1;
    padding: 30px;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding: 20px 30px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
