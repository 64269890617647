import React, {useContext, useState} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import {Button} from '../Widgets';
import Project from '../Models/Project';
import Dispatch from '../Models/Dispatch';
import formatValidator from '../Utils/formatValidator';
import {ErrProject, errorHandler} from '../errors';
import {message, Table} from 'antd';
import {navigate} from 'gatsby';
import Field from '../Components/Field';
import useDispatchList from '../hooks/use-dispatch-list';
import {date, fillZero} from '../Utils';

const compositeDisplayId = (values) => {
  const display_id = `W${date.getIdDate(
    values.created,
  )}${values.staff_name.slice(0, 1)}-${fillZero(values.serial_number, 4)}`;
  return display_id;
};

const {isNotEmpty} = formatValidator;

const LABELS = Project.labels;

export default function CopyProjectForm({record, onUpdate}) {
  const [selectedDispatchIds, setSelectedDispatchIds] = useState([]);
  const app = useContext(Context);
  const edit = !!record.id;
  const [filters] = useState({
    current: 1,
    pageSize: 20,
  });
  const {records: dispatches, loading: loadingDispatches} = useDispatchList({
    project: record,
    filters,
  });

  const valid = () => {
    if (!isNotEmpty(record.name)) {
      throw new ErrProject('專案名稱不能為空');
    }
  };

  const copy = async () => {
    const action = '複製';
    if (!window.confirm('確定要複製專案？')) {
      return;
    }

    app.actions.setModalLoading(true);
    try {
      let {id, _id, created, updated, ...data} = record;
      data.name = data.name + ' - 複製';

      // 1. copy project
      message.loading({
        content: '正在複製專案',
        key: 'copy',
      });
      let resp = await app.actions.addProject({
        query: {},
        data,
      });

      // 2. copy dispatches
      message.loading({
        content: '正在複製工單以及製程項目',
        key: 'copy',
      });
      let _dispatches = await app.actions.copyDispatches({
        project: resp.id,
        dispatches: selectedDispatchIds,
      });

      // 3. edit dispatches
      message.loading({
        content: '正在組成工單單號',
        key: 'copy',
      });

      let originalDispatches = selectedDispatchIds.reduce((sum, id) => {
        let _d = dispatches.find((d) => d.id === id);
        sum.push(_d);
        return sum;
      }, []);

      _dispatches = _dispatches.map((d, idx) => ({
        ...originalDispatches[idx], // spread metadata
        ...d, // spread new id and serial_num , version
        display_id: compositeDisplayId({...originalDispatches[idx], ...d}),
      }));

      await app.actions.editDispatches(_dispatches);

      onUpdate();
      app.actions.setModal();

      message.success(action + '成功，即將跳轉至新的專案頁面。');
      navigate(`/project?id=${resp.id}`);
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setModalLoading(false);
  };

  return (
    <Wrapper>
      <h3>複製專案</h3>
      <div className="content">
        <Field label={Project.labels['name']}>{record.name}</Field>
        <div style={{color: '#707070'}}>選擇工單</div>
        <Table
          loading={loadingDispatches}
          rowKey="id"
          columns={[
            {
              title: Dispatch.labels['display_id'],
              dataIndex: 'display_id',
            },
            {
              title: Dispatch.labels['version'],
              dataIndex: 'version',
            },
          ]}
          dataSource={dispatches}
          rowSelection={{
            selectedRowKeys: selectedDispatchIds,
            onChange: (keys) => setSelectedDispatchIds(keys),
          }}
        />
      </div>
      <div className="footer">
        <Button onClick={copy}>複製</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    padding: 20px 30px;
  }

  & > .content {
    flex: 1;
    padding: 30px;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding: 20px 30px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
