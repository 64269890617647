class Dispatch {
  constructor(record) {
    const {
      id = '',
      serial_number = null,
      display_id = '',
      created = parseInt(new Date().getTime() / 1000),
      final_date = parseInt(new Date().getTime() / 1000),
      version = 1,
      project = null,
      note = [],
      owner = null,
      worker = null,
      staff = null,
      staff_name = '',
      staff_fullname = '',
    } = record || {};

    this.id = id;
    this.serial_number = serial_number;
    this.display_id = display_id;
    this.created = created;
    this.final_date = final_date;
    this.version = version;
    this.project = project;
    this.note = note;
    this.owner = owner;
    this.worker = worker;
    this.staff = staff;
    this.staff_name = staff_name;
    this.staff_fullname = staff_fullname;
  }
}

Dispatch.labels = {
  id: '系統編號',
  serial_number: '流水號', // 系統產生
  display_id: '工單單號', //Wkey單日期＿(可輸入一個英文大寫字母：填單人英文名）-XXXX（四碼流水號，自系統開始後開始流水編碼，不重置）
  created: '工單日期',
  final_date: '交貨日期', //指最終工廠交給感官的預設日期，建立後不會修改，預先會抓緩衝日期，會比顧客交貨日期再早幾天
  version: '版本',
  project: '專案編號',
  note: '固定項目', // 多選陣列
  owner: '客戶編號',
  worker: '工務編號',
  worker_name: '工務編號',
  staff: '業務編號',
  staff_name: '業務名稱', // for 工單報價單
  staff_fullname: '業務名稱',
};

module.exports = Dispatch;
